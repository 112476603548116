<template>
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="编辑商品"
    ></el-page-header>
    <div class="select-container">
      <el-form
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        label-position="left"
        v-loading.fullscreen.lock="fullscreenLoading"
      >
        <el-form-item label="商品名称" required>
          <el-input style="width: 30%" v-model="title"></el-input>
        </el-form-item>
        <el-form-item label="商品数量" required>
          <el-input-number
            v-model="amount"
            :step="1"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="商品单位" required>
          <el-input
            style="width: 30%"
            placeholder="个、箱、包..."
            v-model="unit"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品分类" required>
          <el-select v-model="classify" placeholder="请选择商品类别">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品状态" required>
          <el-select v-model="storeState" placeholder="商品状态">
            <el-option
              v-for="item in storeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品图片">
          <input type="file" :disabled="disabledImage" @change="onchangemd" />
          <div class="files_list">
            <div
              class="files_list_childer"
              v-for="(item, index) in imageInfo"
              :key="index"
            >
              {{ item.imageName }}
              <span @click="files_D(item)" class="files_D el-icon-close"></span>
            </div>
          </div>
          <div class="imgList">
            <div v-for="(item, index) in imageInfo" :key="index">
              <span @click="image_D(item)" class="icon el-icon-error"></span>
              <img :src="item.imageUrl" alt="" />
            </div>
          </div>
        </el-form-item>
        <el-form-item label="入库价" required>
          <el-input-number
            v-model="inPrice"
            :precision="2"
            :step="1"
            :min="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="出库价" required>
          <el-input-number
            v-model="outPrice"
            :precision="2"
            :step="1"
            :min="0.1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="合同编号">
          <el-input style="width: 30%" v-model="productId" disabled></el-input>
        </el-form-item>
        <el-form-item label="商品ID">
          <el-input style="width: 30%" v-model="productID" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属会员">
          <el-input
            style="width: 30%"
            v-model="belongToVip"
            placeholder="请输入会员名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-input
            style="width: 30%"
            v-model="belongToCompany"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺">
          <el-input
            style="width: 30%"
            v-model="store_product"
            placeholder="请输入店铺"
          ></el-input>
        </el-form-item>
        <el-form-item label="入库时间">
          <el-input style="width: 30%" v-model="inDB" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="保质期">
          <el-input
            style="width: 30%"
            v-model="expiration"
            placeholder="请输入保质期（天）"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="上次修改时间">
          <el-input style="width: 30%" v-model="lastFixDB" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            style="width: 30%"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存修改</el-button>
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      productId: "",
      title: "",
      picture: "",
      imageInfo: "",
      amount: "",
      classify: "",
      inPrice: "",
      unit: "",
      outPrice: "",
      productID: "",
      belongToVip: "",
      belongToCompany: "",
      store_product: "",
      inDB: "",
      productNo: "",
      expiration: "",
      lastFixDB: "",
      remark: "",
      fullscreenLoading: true,
      disabledImage: false,
      storeState: '',
      // 分类如果多可以单独分离出来
      options: [
        {
          value: "BH",
          label: "百货",
        },
        {
          value: "TH",
          label: "特惠",
        },
        {
          value: "KQ",
          label: "卡券",
        },
        {
          value: "MZ",
          label: "美妆",
        },
        {
          value: "JZ",
          label: "家电",
        },
        {
          value: "MS",
          label: "美食",
        },
        {
          value: "FS",
          label: "服饰",
        },
        {
          value: "QT",
          label: "其它",
        },
      ],
      // 商品状态
      storeOptions: [
        {
          value: "u",
          label: "在售",
        },
        {
          value: "d",
          label: "下架",
        },
        {
          value: "del",
          label: "删除",
        },
      ],
    };
  },
  created() {
    const productId = this.$route.params.id;
    axios.post(this.GLOBAL.api + "/product/info", { productId }).then((res) => {
      const info = res.data[0];
      console.log("info:", info);
      this.title = info.title;
      this.amount = info.amount;
      this.imageInfo = info.picture ? JSON.parse(info.picture) : [];
      this.classify = info.classify;
      this.storeState = info.is_del;
      this.inPrice = info.inPrice;
      this.unit = info.unit ? info.unit : "";
      this.outPrice = info.outPrice;
      this.belongToVip = info.belongToVip;
      this.productID = info.productID;
      this.belongToCompany = info.belongToCompany;
      this.store_product = info.store_product;
      this.inDB = info.inDB;
      this.productId = info.productNo;
      // this.productNo = info.productNo;
      this.expiration = info.expiration;
      this.lastFixDB = info.lastfixDB;
      this.remark = info.remark;
      this.fullscreenLoading = false;
    });
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    // delete image
    image_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    files_D(e) {
      this.imageInfo = this.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    // update image
    onchangemd(e) {
      this.disabledImage = true;
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
        this.disabledImage = false;
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.imageInfo.push({
            id: this.imageInfo.length,
            imageName: files.name,
            imageUrl: res.data,
          });
        });
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.disabledImage = false;
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
        this.disabledImage = false;
      }
    },
    onSubmit() {
      this.lastFixDB = moment().format("YYYY-MM-DD HH:mm:ss");
      const productId = this.$route.params.id;
      const title = this.title;
      const amount = this.amount;
      const classify = this.classify;
      const storeState = this.storeState;
      const inPrice = +this.inPrice;
      const unit = this.unit;
      const outPrice = +this.outPrice;
      const belongToVip = this.belongToVip;
      const belongToCompany = this.belongToCompany;
      const store_product = this.store_product;
      const lastFixDB = this.lastFixDB;
      const remark = this.remark;
      const expiration = this.expiration;
      const imageInfo = JSON.stringify(this.imageInfo);
      axios
        .post(this.GLOBAL.api + "/amend/product", {
          productId,
          title,
          amount,
          classify,
          storeState,
          inPrice,
          unit,
          outPrice,
          belongToVip,
          imageInfo,
          belongToCompany,
          store_product,
          expiration,
          lastFixDB,
          remark,
        })
        .then((data) => {
          if (data.data.statusCode == 200) {
            this.$confirm("商品信息修改成功！", "提示", {
              confirmButtonText: "返回列表",
              cancelButtonText: "继续修改",
              type: "success",
            })
              .then(() => {
                this.$router.push("/product/list");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "继续修改",
                });
              });
          } else {
            this.$message({
              message: "修改失败，请联系管理员",
              type: "warning",
            });
          }
        });
    },
    onCancel() {
      this.$confirm("您还未保存修改, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/product/list");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
.select-container {
  padding: 1% 3%;
}
.box-card {
  width: 100%;
  height: 100%;
}
.imgList {
  display: flex;
  justify-self: flex-start;
}
.imgList div {
  width: 15%;
  margin-right: 15px;
}
.imgList img {
  width: 100%;
}
.icon {
  display: none;
}
.imgList div:hover .icon {
  display: inline-block;
}
</style>